export class ObjectUtils {

	/**
	 * Safely traverse through an object with a dot notated path string
	 * @param path
	 * @param obj
	 */
	public static resolveDotNotationPath(path, obj) {
		return path?.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || self);
	}

	public static unfreeze(obj: any) {
		return JSON.parse(JSON.stringify(obj));
	}
}
