<div
        *ngIf="!actionInProgress?.itemIds?.includes(item.id)"
        class="review"
        [ngClass]="projectCartService.getReviewStatusClass(item.approved)"
        [matMenuTriggerFor]="showActions ? reviewMenu : placeholder"
        (click)="$event.stopImmediatePropagation()"
>
    <mat-icon class="mt-1" inline>{{ projectCartService.getReviewStatusIcon(item.approved) }}</mat-icon>
    <span *ngIf="showStatusText" class="status">{{ projectCartService.getReviewStatus(item.approved) }}</span>
    <mat-icon *ngIf="showActions" inline>expand_more</mat-icon>

    <mat-menu #reviewMenu="matMenu" class="review-context-menu">
        <div mat-menu-item class="context-menu-item" (click)="onItemAction('approve')">
            <mat-icon inline>done</mat-icon
            ><ng-container i18n="Review Context Menu Item@@cartListItemRowReviewApprove">Approve</ng-container>
        </div>
        <div mat-menu-item class="context-menu-item" (click)="onItemAction('reject')">
            <mat-icon inline>close</mat-icon
            ><ng-container i18n="Review Context Menu Item@@cartListItemRowReviewReject">Reject</ng-container>
        </div>
    </mat-menu>
</div>
<div *ngIf="actionInProgress?.itemIds?.includes(item.id)">
    <mat-spinner [diameter]="20"></mat-spinner>
</div>

<!-- workaround to display the mat menu conditionally -->
<mat-menu #placeholder="matMenu" style="display: none"></mat-menu>
