import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CartStore } from "./cart.store";
import {CartItem, CartItemGroup, CartItemType, ProjectCartItemDto, ProjectCartItemReviewDto, ProjectCartItemsReviewDto} from "./cart.model";
import {environment} from '../../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {DefaultResponse} from '../session';
import {EncodeJobRequest, Project, ProjectsStore} from '../project';
import { of, take } from "rxjs";
import { CartJobDto } from "../../../../../../api/src/encode/models/job-def.dto";
import {CreativeUnitPackageQuery} from "../ad/ad-unit-package.query";
import { CartQuery } from "./cart.query";
import { Utils } from "../../../../../../api/src/project/project.utils";
import { StoryFormat } from "../story-format";

@Injectable({ providedIn: 'root' })
export class CartService {

	constructor(
		private cartStore: CartStore,
		private cartQuery: CartQuery,
		private projectStore: ProjectsStore,
		private creativeUnitPackageQuery: CreativeUnitPackageQuery,
		private http: HttpClient
	) {}

	public get(projectId: Project['id'], listOnly?: boolean) {
		return this.http.get<DefaultResponse<CartItem[]>>(`${environment.apiUrl}/projects/${projectId}/cart-items${(listOnly ? '/list': '')}`).pipe(
			map(response => response?.data),
			map(items => items.map(item => this.prepareForAkita(item))),
			tap(items => this.set(items))
		);
	}

	public add(projectId: Project['id'], itemDto: ProjectCartItemDto, skipHttp: boolean = false) {
		if (skipHttp) {
			const dirtyItem = {...itemDto, dirty: true} as any;
			this.cartStore.add(this.prepareForAkita(dirtyItem as CartItem));
			return of(this.prepareForAkita(itemDto as CartItem));
		} else {
			return this.http.post<DefaultResponse<CartItem>>(`${environment.apiUrl}/projects/${projectId}/cart-items`,
				this.prepareForApi(itemDto as CartItem)
			).pipe(
				map(response => response?.data),
				tap(item => this.cartStore.add(this.prepareForAkita(item)))
			)
		}
	}

	public update(projectId: Project['id'],
				  id: CartItem['id'],
				  itemDto: Partial<ProjectCartItemDto>,
				  skipHttp: boolean = false,
				  updateStore: boolean = false) {
		if (skipHttp) {
			const dirtyItem = {...itemDto, dirty: true};
			return of(this.cartStore.update(id, dirtyItem));
		} else {
			return this.http.patch<DefaultResponse<CartItem>>(`${environment.apiUrl}/projects/${projectId}/cart-items/${id}`,
				this.prepareForApi(itemDto as CartItem)
			).pipe(
				map(response => response?.data),
			).pipe(
				tap(item => {
					if (updateStore) {
						this.cartStore.update(id, this.prepareForAkita(item))
					}
				})
			);
		}
	}

	public remove(projectId: Project['id'], id: CartItem['id'], skipHttp: boolean = false) {
		if (skipHttp) {
			return of(this.cartStore.remove(id));
		} else {
			return this.http.delete<DefaultResponse<{id: string}>>(`${environment.apiUrl}/projects/${projectId}/cart-items/${id}`,
			).pipe(
				map(response => response?.data),
				tap(item => {
					this.cartStore.remove(item.id);
					this.projectStore.update(projectId, project => ({
					...project,
					cartItems: project.cartItems?.filter(i => i.id !== item.id)
					}))
				})
			)
		}
	}

	public clear() {
		this.cartStore.set([]);
	}

	public getItemCheckoutTable(projectId: Project['id'], id: CartItem['id']) {
		return this.http.get(`${environment.apiUrl}/projects/${projectId}/cart-items/${id}/checkout-table`).toPromise();
	}

	public requestPreview(projectId: Project['id'], id: CartItem['id'], request: EncodeJobRequest) {
		return this.http.post(`${environment.apiUrl}/projects/${projectId}/cart-items/${id}/request-preview`, request);
	}

	public submitCartJob(projectId: Project['id'], request: CartJobDto) {
		return this.http.post(`${environment.apiUrl}/projects/${projectId}/submit-cart-job`, request);
	}

	public addReview(projectId: Project['id'], cartItemsReviewDtos: ProjectCartItemsReviewDto, skipHttp: boolean = false) {
		if (skipHttp) {
			return of(this.cartStore.update(cartItemsReviewDtos.items));
		} else {
			return this.http.post<DefaultResponse<CartItem[]>>(`${environment.apiUrl}/projects/${projectId}/cart-items/review`, cartItemsReviewDtos
			).pipe(
				map(response => response?.data),
				tap(items => {
					this.cartStore.upsertMany(items?.map(item => this.prepareForAkita(item)))
				})
			)
		}
	}

	public set(items: CartItem[]) {
		this.cartStore.set(items?.map(item => {
			let foundPackage;
			if (item.itemData.packageId) {
				foundPackage = this.creativeUnitPackageQuery.getValue().entities[item.itemData.packageId];
			}
			const itemWithPackage = {
				...item,
				itemData: {
					...item.itemData,
					package: foundPackage
				}
			};
			return this.prepareForAkita(itemWithPackage);
		}));
	}

	public setActive(id: string) {
		this.cartStore.setActive(id);
	}

	public clearActive() {
		this.cartStore.setActive(null);
	}

	public prepareForAkita(item: CartItem): CartItem {
		// Hygiene: Sync item ad unit values with the ad customizations.
		if (item.type !== CartItemType.AD_UNIT) return item;

		return {
			...item
			// itemData: CreativeUnitWithCustomizations.syncValuesWithCreativeUnit(item.itemData)
		}
	}

	public prepareForApi(item: CartItem): CartItem {
		return {
			...item,
			id: undefined,
			projectId: undefined
			// itemData: CreativeUnitWithCustomizations.syncValuesWithCustomizations(item.itemData)
		}
	}

	convertVideoItemToGroup(item: CartItem): CartItemGroup {
		return {
			id: item.id,
			name: item.name,
			type: item.type,
			items: [item],
			thumbnailUrl: this.cartQuery.getVideoItemThumbnailUrlFromItemData(item.itemData),
			metadata: []
		};
	}

	convertCreativeUnitItemToGroup(item: CartItem): CartItemGroup {
		let thumbnailUrl: string | undefined;
		if (item.itemData.package?.thumbnail) {
			thumbnailUrl = `https://res.cloudinary.com/${environment.cloudinaryCloudName}/image/fetch/c_fill,h_100,w_100/${item.itemData.package?.thumbnail?.assetPath}`;
		}

		return {
			id: item.itemData.packageId,
			name: item.itemData.package?.name || item.itemData.packageId,
			type: item.type,
			parent: item.itemData.package,
			items: [item],
			thumbnailUrl,
			metadata: []
		}
	}

	getReviewStatus(approved?: boolean, short?: boolean): string {
		if (approved === true) {
			return $localize `:Cart Item Review Status@@cartItemReviewStatusApprovedText:Approved`;
		} else if (approved === false) {
			return $localize `:Cart Item Review Status@@cartItemReviewStatusRejectedText:Rejected`;
		} else {
			if (short) {
				return $localize `:Cart Item Review Status@@cartItemReviewStatusPendingText:Pending`;
			} else return $localize `:Cart Item Review Status@@cartItemReviewStatusPendingReviewText:Pending Review`;
		}
	}

	getReviewStatusIcon(approved?: boolean): string {
		if (approved === true) {
			return 'done';
		} else if (approved === false) {
			return 'close';
		} else return 'schedule';
	}

	getReviewStatusClass(approved?: boolean): string {
		if (approved === true) {
			return 'approved';
		} else if (approved === false) {
			return 'rejected';
		} else return 'pending';
	}

	getVideoStoryFormat(workspaceState: any) {
		return Utils.getProjectStoryFormatRaw(workspaceState);
	}

	getVideoFormatText(storyFormat: StoryFormat) {
		if (!storyFormat || !storyFormat.title){
			return;
		}

		return (storyFormat.description  || storyFormat.title)?.replace(/<br ?\/?>/g, ' ').trim()
	}

	getVideoResolution(storyFormat: StoryFormat) {
		if (!storyFormat || !storyFormat.canvasWidth || !storyFormat.canvasHeight){
			return;
		}

		return storyFormat.canvasWidth + 'x' + storyFormat.canvasHeight

	}

	setCartItemsFromProjectId(projectId: string) {
		this.get(projectId).pipe(take(1)).subscribe();
	}
}
