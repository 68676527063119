import { merge } from "lodash";
import { ObjectUtils } from "../../_core/utils/utils.object";
import { PublicBusiness } from "../../business/business.entity";
import { CreativeUnitPackageDto } from "../models/creative-unit-package.dto";
import { CustomizationOptionDto } from "../models/customization/customization-option.dto";
import { CustomizationUtils } from "./customization.utils";
import { LayersUtils } from "./layers.utils";
import { CreativeUnitWithCustomizationsDto } from "../models/creative-unit/creative-unit.dto";

export class MergePropertiesUtils {
	/**
	 * Creates a state of all important package details to be used to easily traverse
	 * the state and find properties to use for merge tags.
	 */
	public static getMergeableProperties(pkg: CreativeUnitPackageDto, business?: PublicBusiness, unit?: CreativeUnitWithCustomizationsDto) {
		let customizations = CustomizationUtils.findAllCustomizations(unit?.customizations || pkg?.customizations || []);

		let state = {
			customizations: customizations.reduce((acc, customization) => {
				acc[customization.id] = customization;
				return acc;
			}, {}),
			layers: LayersUtils.flattenLayers(unit?.layers || pkg?.layers || []).reduce((acc, layer) => {
				acc[layer.id] = layer;
				return acc;
			}, {}),
			variables: unit?.variables || pkg?.variables || {},
			business: business || {},
			activeCreativeUnit: unit
		};

		// Merge customization value variables into the state.
		for (let customization of customizations) {
			let variables = (customization.value as CustomizationOptionDto)?.variables;

			// Check for text input
			if (customization.type === 'text' && customization.variableKey) {
				variables = { [customization.variableKey]: customization.value };
			}

			if (variables) {
				let stateVariables = ObjectUtils.unfreeze(state.variables);
				let customizationVariables = ObjectUtils.unfreeze(variables);
				state.variables = merge(stateVariables, customizationVariables);
			}
		}

		// Creative Unit variable overrides should override even customization changes.
		// console.log('Checking for variable overrides', unit.variables, unit.variableOverrides);
		if (unit?.variableOverrides) {
			let stateVariables = ObjectUtils.unfreeze(state.variables);
			let unitVariables = ObjectUtils.unfreeze(unit?.variableOverrides);
			state.variables = merge(stateVariables, unitVariables);
			// console.log('Merged Variables', unit.variables);
		}

		return state;
	}
}
