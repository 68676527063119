/**
 * Merge Tags Utils
 * A set of utilities for working with merge tags in strings.
 *
 * Example usage:
  	const template = `
  		Hello {{user.name}}, you have {{fn(return state.variables.user?.subscriptions?.length;)}} subscription that
		is {{user.subscriptions->find:plan:eq:premium->details.value}}.
	";

	const variables = {
		user: {
		name: "Alice",
		subscriptions: [
			{ plan: "free", details: "Basic access" },
			{ plan: "premium", details: "Full access with premium features" }
		],
		emails: [{ address: "alice@example.com" }]
		}
	};
 */
export class MergeTags {
	/**
	 * Applies merge tags to a template string using the provided variables.
	 *
	 * @param {string} templateString - The template string to apply merge tags to.
	 * @param {object} variables - The variables to use for replacing merge tags.
	 * @returns {string} The template string with merge tags replaced by their corresponding values.
	 */
	public static applyMergeTagsToString(templateString, variables) {
		// Match paths with a find operation or a direct path within merge tags
		// console.log('Template String:', templateString); // Debugging log

		// If adjusting this regex, make sure that it is handling all use cases: https://regex101.com/r/w69FS2/1
		return templateString?.replace(
			/\{\{\s*(?:fn\(([\s\S]*?)\)|([\w\s\/\-\>\:\._]+?)(?:\s*\|\|\s*'(.*?)')?)\s*\}\}/g,
			(match, fn, path, defaultValue) => {
				// console.log('Match found:', match, fn, path, defaultValue); // Debugging log
				// console.group(match); // Debugging log
				let value;

				if (fn) {
					// console.log('Function:', fn); // Debugging log
					// Evaluate the expression
					// console.groupEnd(); // Debugging log
					return this.evaluateExpression(fn, variables);
				} else {
					// console.log('Primary Path:', path, 'Default Value:', defaultValue); // Debugging log
					value = this.getValueByPath(variables, path) || '';
					// console.log('Value:', value, path); // Debugging log
				}

				// If the value is undefined or an empty string, use the default value.
				// console.groupEnd(); // Debugging log
				return value !== undefined && value !== null && value !== '' ? value : defaultValue || '';
			}
		);
	}

	/**
	 * Evaluates an expression found within fn() in merge tags.
	 * Supports nested properties, arithmetic operations, and conditional expressions.
	 *
	 * @param expression - The expression to evaluate.
	 * @param variables - The context variables to use for evaluation.
	 * @returns The result of the evaluated expression.
	 */
	private static evaluateExpression(expression: string, state: any): any {
		// Define a function with 'state' as an explicit parameter
		let dynamicFunction = new Function('state', expression);
		// console.log('Dynamic Function:', state); // Debugging log

		// Invoke the function with 'state' as the argument
		let result = dynamicFunction(state);

		return result;
	}

	/**
	 * Retrieves the value from an object using a given path.
	 * The path can include dot notation for nested properties and '->' for array find operations.
	 * If the value is not found, undefined is returned.
	 *
	 * @param variables - The object to search for the value.
	 * @param path - The path to the desired value.
	 * @returns The value found at the specified path, or undefined if not found.
	 */
	private static getValueByPath(variables, path) {
		// Initialize the current object to the passed variables
		let current = variables;

		// Split the path on '->', which may include a 'find' operation or be a direct path
		let pathSegments = path.split('->');

		// Iterate over the segments to process each one
		for (let i = 0; i < pathSegments.length; i++) {
			let segment = pathSegments[i];
			// console.log('Segment:', segment, segment.startsWith('find'), i, pathSegments);

			// Handle the find operation
			if (segment.startsWith('find')) {
				//&& i < pathSegments.length - 1
				// console.log('Find operation:', segment);
				// Remove find: from the segment
				let searchQuery = segment.replace('find:', '');
				current = this.findInArray(current, searchQuery);

				// console.log('Found:', current, searchQuery);

				if (current === undefined) {
					// If nothing is found, return undefined immediately
					return undefined;
				}
			} else {
				// Split the segment into subparts in case of dot notation
				let keys = segment.split('.');
				for (let key of keys) {
					if (current?.[key] === undefined) {
						return undefined; // If the key is not found, return undefined
					}
					current = current[key];
				}
			}
		}
		return current;
	}

	/**
	 * Finds an item in an array based on a search query.
	 * @param array - The array to search in.
	 * @param searchQuery - The search query in the format "key:condition:value".
	 * @returns The found item or undefined if not found.
	 * @throws Error if the search condition is unsupported.
	 */
	private static findInArray(array, searchQuery) {
		// Split the search query into its components: key, condition, and value
		let [key, condition, searchValue] = searchQuery.split(':');
		// Based on the condition, perform the appropriate search
		switch (condition) {
			case 'eq': // Equals condition
				return array.find(item => item[key] === searchValue);
			case 'neq': // Not equals condition
				return array.find(item => item[key] !== searchValue);
			default:
				throw new Error(`Unsupported search condition: ${condition}`);
		}
	}
}
