import { Injectable } from '@nestjs/common';

import { Crypt } from '../crypt';

const emailRegex = new RegExp(
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);

const fakeDotProviders: string[] = ['gmail.com', 'googlemail.com'];

@Injectable()
export class FraudPreventionService {
	private readonly signingKey: string = process.env.FRAUD_PREVENTION_SIGNING_KEY;

	constructor() {}

	// Creates an encryption key for the given user
	// based on the system signing key.
	public getUserKey(user): string | boolean {
		if (!this.signingKey) {
			return false;
		}
		return Crypt.createSHA256Hash(this.signingKey, user);
	}

	// Generates a crypto-random 8-bit hex string.
	public createNonce(): string {
		return Crypt.randomHex(8);
	}

	// Encrypt a string of data.
	public encryptData(data: string, key: string, nonce: string): string {
		return Crypt.encrypt(data, key, nonce);
	}

	// Decrypt an encrypted string of data.
	public decryptData(data: string, key: string, nonce: string): string {
		return Crypt.decrypt(data, key, nonce);
	}

	// Creates a reasonably canonical email address.
	// Used to help prevent users from providing slight
	// variations on their addresses to gain extra entries.
	public normalizeEmail(email: string): string {
		// Strip "+"
		// Remove "." from the email name (for gmail at least)
		// Other things?

		let segments = email.toLowerCase().split('@');

		if (segments[0].indexOf('+') !== -1) {
			segments[0] = segments[0].split('+')[0];
		}

		if (fakeDotProviders.indexOf(segments[1]) !== -1) {
			segments[0] = segments[0].replace(/\./g, '');
		}

		return segments[0] + '@' + segments[1];
	}

	public validateEmail(email: string, restrictions: string[]): boolean {
		email = this.normalizeEmail(email);

		// Step one, make sure this is a real email at all
		let badEmail = !email.match(emailRegex);

		if (badEmail) {
			return false;
		}

		if (!restrictions) {
			restrictions = [];
		}

		// Step two, iterate rule restrictions
		restrictions.forEach(r => {
			if (email.match(new RegExp(r))) {
				badEmail = true;
			}
		});

		// This email has been explicitly blacklisted
		if (badEmail) {
			return false;
		}

		return true;
	}

	// TODO
	public normalizePhone(phone: string) {
		return phone.toString().replace(/[\.\+\-\s]/g, '');
	}

	// TODO
	public validatePhone(phone: string, countries?: string[]) {
		if (!phone) {
			return false;
		}

		// Remove non-numbers
		phone = phone.replace(/[^0-9]+/g, '');

		// If no validators are passed,
		// default to checking US phone numbers
		if (!countries || !countries.length || !Array.isArray(countries)) {
			countries = ['US'];
		}

		for (let i = 0; i < countries.length; i++) {
			let country = countries[i];
			switch (country) {
				case 'US':
				default:
					// +1 (###) ###-#### | (###) ###-#####
					// At some point we probably want to validate area codes
					// and prefixes. Right now we just test to see if it's
					// US (+1) and 10 digits.
					if (!phone.match(/(^1[0-9]{10}$)|(^[0-9]{10}$)/)) {
						return false;
					}
					break;
			}
		}

		return true;
	}
}
