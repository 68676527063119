import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { ConnectorConfig, DataSourceConnectorType, DataSourceContext, ItemDef, QueryParams, SortDef } from './models';
import { DataSourceUtils } from './data-source.utils';
import { ConnectorConfigCSV } from './connectors/csv/models';
import { Business, PublicBusiness } from '../business/business.entity';
import { ConnectorConfigSmartSheet } from './connectors/smartsheet/models';
import { PublicSite, Site } from '../site/site.entity';

export type PublicDataSource = Pick<DataSource,
	'id' | 'name' | 'slug' | 'connectorType' | 'siteId' | 'businessId'
> & {
	site?: PublicSite;
	business?: PublicBusiness;
	config?: {
		target: string;
		context?: DataSourceContext[];
		mapping?: ItemDef[];
		params?: QueryParams;
		columnSeparator?: string;
		environment?: string;
		sortableFields?: SortDef[];
		previewPath?: string;
		tagPath?: string;
	};
}

@Entity('dataSources')
export class DataSource {
	constructor(value?: Partial<DataSource>) {
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	name: string;

	@Column({type: "text", unique: true})
	slug: string;

	@Column({ type: 'enum', enum: DataSourceConnectorType })
	connectorType: DataSourceConnectorType;

	@Column('uuid', { nullable: false })
	siteId: string;
	@ManyToOne(
		() => Site,
		{
			onDelete: 'CASCADE',
			nullable: false
		}
	)
	@JoinColumn({ name: 'siteId' })
	site: Site;

	@Column('uuid', { nullable: true })
	businessId?: string;
	@ManyToOne(
		() => Business,
		{
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	@JoinColumn({ name: 'businessId' })
	business?: Business;

	@Column('text', {
		name: 'config',
		nullable: true
	})
	_config: string;
	public get config(): ConnectorConfig | ConnectorConfigCSV | ConnectorConfigSmartSheet {
		return DataSourceUtils.decryptConfig(this._config, this.businessId);
	}
	public set config(value: ConnectorConfig | ConnectorConfigCSV | ConnectorConfigSmartSheet) {
		this._config = DataSourceUtils.encryptConfig(value, this.businessId);
	}

	public toPublic() {
		const pub: Partial<PublicDataSource> = {
			id: this.id,
			name: this.name,
			slug: this.slug,
			connectorType: this.connectorType,
			siteId: this.siteId,
			businessId: this.businessId
		};

		if(this.site) {
			pub.site = new Site(this.site).toPublic();
		}

		if(this.business) {
			pub.business = new Business(this.business).toPublic();
		}

		// The config should specifically have keys whitelisted so
		// credentials are not leaked.
		if(this.config) {
			let conf = this.config;
			pub.config = {
				target: conf.target
			};

			if(conf.context) {
				pub.config.context = conf.context;
			}

			if(conf.mapping) {
				pub.config.mapping = conf.mapping;
			}

			if (conf.params) {
				pub.config.params = conf.params;
			}

			if (conf.sortableFields) {
				pub.config.sortableFields = conf.sortableFields;
			}

			if (conf.previewPath) {
				pub.config.previewPath = conf.previewPath;
			}

			if (conf.tagPath) {
				pub.config.tagPath = conf.tagPath;
			}

			if (conf['columnSeparator']) {
				pub.config.columnSeparator = conf['columnSeparator'];
			}
			if (conf['environment']) {
				pub.config.environment = conf['environment'];
			}
		}

		return pub as PublicDataSource;
	}
}
