import { Crypt } from '../_core/crypt';

export class DataSourceUtils {
	public static encryptConfig<T>(value: T, organizationId?: string): string {
		if (!value || !process.env.PII_SIGNING_KEY || !process.env.PII_SIGNING_OFFSET) {
			return;
		}
		let stringValue;
		try {
			stringValue = JSON.stringify(value);
		} catch (err) {
			console.log(err);
			return;
		}
		try {
			stringValue = Crypt.encrypt(
				stringValue,
				Crypt.createSHA256Hash(process.env.PII_SIGNING_KEY, organizationId),
				process.env.PII_SIGNING_OFFSET
			);
		} catch (err) {
			console.log(err);
			return;
		}
		return stringValue;
	}

	public static decryptConfig<T>(value: string, organizationId: string): T {
		if (!value || !process.env.PII_SIGNING_KEY || !process.env.PII_SIGNING_OFFSET) {
			return;
		}
		let parsedValue: T;
		try {
			parsedValue = JSON.parse(
				Crypt.decrypt(value, Crypt.createSHA256Hash(process.env.PII_SIGNING_KEY, organizationId), process.env.PII_SIGNING_OFFSET)
			);
		} catch (err) {
			if (process.env.DEBUG) {
				console.log(err);
			}
			return;
		}

		return parsedValue;
	}
}
